<template>
    <div class="h-100 d-flex justify-center align-center">
      <v-form ref="form" v-model="valid" class="form_container" lazy-validation>
        <div class="mb-2">
          <logo />
        </div>
        <div class="title_container">
          <p class="title">Activate your account</p>
          <span class="subtitle"
            >a verification code has been sent to {{ $route.query.email }}.</span
          >
        </div>
  
        <v-text-field
          label="Verification Code"
          color="secondary"
          v-model="authModel.code"
          prepend-inner-icon="mdi-key"
          placeholder="code"
          outlined
          :rules="[
            (v) => !!v || 'Code is required',
       
          ]"
          class="w-100"
        />
      
  
        <button
          :disabled="!valid"
          @click.prevent="activateUserAccount"
          title="Sign In"
          class="sign-in_btn"
          :class="!valid ? 'disable-btn' : 'primary lighten-1'"
        >
          <span>Verification of email</span>
        </button>
  
        <div class="separator d-none">
          <hr class="line" />
          <span>Or</span>
          <hr class="line" />
        </div>
        <button title="Sign In " class="sign-in_ggl d-none">
          <svg
            height="18"
            width="18"
            viewBox="0 0 32 32"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <path
                d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                id="A"
              ></path>
            </defs>
            <clipPath id="B"></clipPath>
            <g transform="matrix(.727273 0 0 .727273 -.954545 -1.45455)">
              <path fill="#fbbc05" clip-path="url(#B)" d="M0 37V11l17 13z"></path>
              <path
                fill="#ea4335"
                clip-path="url(#B)"
                d="M0 11l17 13 7-6.1L48 14V0H0z"
              ></path>
              <path
                fill="#34a853"
                clip-path="url(#B)"
                d="M0 37l30-23 7.9 1L48 0v48H0z"
              ></path>
              <path
                fill="#4285f4"
                clip-path="url(#B)"
                d="M48 48L17 24l-4-3 35-10z"
              ></path>
            </g>
          </svg>
          <span>Sign In with Google</span>
        </button>
        <button title="Sign In " class="sign-in_apl d-none">
          <svg
            preserveAspectRatio="xMidYMid"
            version="1.1"
            viewBox="0 0 256 315"
            height="20px"
            width="16px"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                fill="#ffffff"
                d="M213.803394,167.030943 C214.2452,214.609646 255.542482,230.442639 256,230.644727 C255.650812,231.761357 249.401383,253.208293 234.24263,275.361446 C221.138555,294.513969 207.538253,313.596333 186.113759,313.991545 C165.062051,314.379442 158.292752,301.507828 134.22469,301.507828 C110.163898,301.507828 102.642899,313.596301 82.7151126,314.379442 C62.0350407,315.16201 46.2873831,293.668525 33.0744079,274.586162 C6.07529317,235.552544 -14.5576169,164.286328 13.147166,116.18047 C26.9103111,92.2909053 51.5060917,77.1630356 78.2026125,76.7751096 C98.5099145,76.3877456 117.677594,90.4371851 130.091705,90.4371851 C142.497945,90.4371851 165.790755,73.5415029 190.277627,76.0228474 C200.528668,76.4495055 229.303509,80.1636878 247.780625,107.209389 C246.291825,108.132333 213.44635,127.253405 213.803394,167.030988 M174.239142,50.1987033 C185.218331,36.9088319 192.607958,18.4081019 190.591988,0 C174.766312,0.636050225 155.629514,10.5457909 144.278109,23.8283506 C134.10507,35.5906758 125.195775,54.4170275 127.599657,72.4607932 C145.239231,73.8255433 163.259413,63.4970262 174.239142,50.1987249"
              ></path>
            </g>
          </svg>
          <span>Sign In with Apple</span>
        </button>
        <router-link class="note primary--text" to="/auth/signup"
          >Don't have account ? Sign Up</router-link
        >
        <p class="note mb-0">Terms of use &amp; Conditions</p>
      </v-form>
    </div>
  </template>
  
  <script>
  import Logo from "@/components/user/AuthLogo.vue";
  import { activateUserAccount } from "@/api/user";
  import { methods } from "@/utils/constant.js";
  
  export default {
    components: { Logo },
    data() {
      return {
        valid: false,
        loading: false,
        authModel: {},
      };
    },
    methods: {
      async activateUserAccount() {
        if (this.$refs.form.validate()) {
          this.loading = true;
  
          try {
            await activateUserAccount({
              email: this.$route.query.email,
              code: this.authModel.code
            });
            methods.dispatchSuccess(this.$store,
            "Your email has been successfully verified");
  
            // location.reload();
            this.$router.push(`/auth/login`)
          } catch (error) {
            console.log("login error", error);
            methods.dispatchError(
              this.$store,
              "Something went wrong"
            );
          }
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style  lang="scss" scoped>
  .logo {
    width: 9em;
    height: 2rem;
    background: linear-gradient(to top, #00154c, #12376e, #23487f);
    color: #fff;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
    overflow: hidden;
  }
  
  .logo span {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: top 0.5s;
  }
  
  .form_container {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 50px 40px 20px 40px;
    background-color: #ffffff;
    box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
      0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
      0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    font-family: "Inter", sans-serif;
  }
  
  .title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
    color: #212121;
  }
  
  .subtitle {
    font-size: 0.725rem;
    max-width: 80%;
    text-align: center;
    line-height: 1.1rem;
    color: #8b8e98;
  }
  
  .input_container {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .icon {
    width: 20px;
    position: absolute;
    z-index: 99;
    left: 12px;
    bottom: 9px;
  }
  
  .input_label {
    font-size: 0.75rem;
    color: #8b8e98;
    font-weight: 600;
  }
  
  .input_field {
    width: auto;
    height: 40px;
    padding: 0 0 0 40px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .input_field:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
  }
  
  .sign-in_btn {
    width: 100%;
    height: 40px;
    border: 0;
    //   background: #115dfc;
    border-radius: 7px;
    outline: none;
    color: #ffffff;
    cursor: pointer;
  }
  
  .sign-in_ggl {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #ffffff;
    border-radius: 7px;
    outline: none;
    color: #242424;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    cursor: pointer;
  }
  
  .sign-in_apl {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #212121;
    border-radius: 7px;
    outline: none;
    color: #ffffff;
    border: 1px solid #e5e5e5;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    cursor: pointer;
  }
  
  .separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color: #8b8e98;
  }
  
  .separator .line {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e8e8e8;
  }
  
  .note {
    font-size: 0.75rem;
    color: #8b8e98;
    text-decoration: underline;
  }
  </style>